.machineselect {
    width: 240px;
    margin-Bottom: 15px;
}

.machine-double-size {
    width: 49%;
}

.remove-button {
    border: none;
    background: transparent;
    float: right;
    padding: 2%;
}

.machine-container {
    margin: 2%;
    padding: 1%;
    border: 1px solid #E4E4E4;
    width: 90%;
}

.machine-details {
    width: 80%
}

.machine-btn {
    width: 65px;
    height: 65px;
    padding: 18px;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #E4E4E4;
}

@media (max-width: 975px) {
    .machine-btn {
        width: 65px;
        height: 59px;
        margin: -4px !important;
    }

    .edit-save-btn {
        width: 200% !important;
        left: -90% !important;
    }
}

@media (max-width: 975px) {
    .machine-btn {
        width: 65px;
        height: 59px;
        margin: -4px !important;
    }

    .edit-save-btn {
        width: 200% !important;
        left: -90% !important;
    }
}

@media (max-width: 600px) {
    .machine-btn {
        width: 50px;
        height: 60px;
        margin: -4px !important;
    }

    .edit-save-btn {
        justify-content: center !important;
        width: 135% !important;
        left: -18% !important;
        bottom: -120px !important;
    }
}