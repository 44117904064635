.home {
    min-height: 100vh;
    width: 100%;
    margin-top: 100px;
    z-index: 250;
    position: relative;
    background: #fff;
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-head-container {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.home-head-container h1 {
    color: #0D4C7A;
    font-family: Nunito Sans;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 125px;
}

.vertical-line {
    border-left: 2px solid black;
    height: 50vh;
    margin: 3% 0px;
    opacity: 0.1;
}

.vertical-line-footer {
    border-left: 2px solid white;
    height: 20vh;
    margin: 3% 0px;
    opacity: 0.1;
}

.certificate-section {
    margin: 100px auto;
}

.social-section {
    padding: 100px;
}

.footer {
    background: #0D4C7A;
    padding: 2% 4%;
    display: flex;
    flex-direction: column;
}

.footer-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff;
}

.footer-main p {
    color: #FFF;
    font-weight: 100;
    opacity: 0.6;
    cursor: pointer;
}

.footer-main a {
    opacity: 1;
    color: #FFF;
}

.emailSubscribe {
    display: flex;
    width: 230px;
    /* padding: 16px; */
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    border: 1.5px solid #E7E8F2;
    background: #FFF;
}

#emailSubscribe {
    border: none;
    appearance: none;
    -webkit-appearance: none;
    outline: none;

}

#emailSubscribe:hover,
#emailSubscribe:active,
#emailSubscribe:focus {
    border: none;
    outline: none;
}

.emailSubscribe label {
    width: 60px;
    height: 52px;
    background: #0081FE;
    color: #FFF;
    border-radius: 0 6px 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icon svg:hover {
    fill: #0081FE !important;
    outline: 1px solid #0081FE !important;
}

@media (max-width: 1200px) {
    .sub-container {
        display: flex;
        flex-direction: column;
    }

    .vertical-line,
    .vertical-line-footer {
        display: none;
    }

    .footer-wrap,
    .certificate-wrap {
        flex-wrap: wrap;
    }

    .home-head-container h1 {
        margin-left: auto;
        text-align: center;
        font-size: 28px;
    }

}

@media (max-width: 600px) {
    .social-section {
        padding: 100px 10px;
    }

    .home {
        margin-left: 20px;
        width: 95%;
    }
}