.noMachinePage {
    padding: 1% 5%;
    height: 80vh;
}

.main-card-container {
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
}

.dashboard-flex{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.total-machine {
    border-radius: 4px;
    background: #E7EDF2;
    padding: 0  0  5%;
    margin-bottom: 20px;
    text-align: center;
    width: 180px;
}

.total-machine p{
    border-radius: 4px 4px 0 0;
    font-size: 10px;
    color: #fff;
    padding: 1%;
    margin: 0;
}

.total-machine h2{
    color: #0D4C7A;
}

.total-machine button{
    color: #0D4C7A;
    text-decoration: underline #0D4C7A;
    border: none;
    background: transparent;
    outline: none;
}

/* .dashboard-flex .myMachinesCardContainer .main-card-container {
   flex-wrap: nowrap;
} */

.machine-card {
    width: 300px;
    height: 750px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 20px;
}

.card-availability {
    border-radius: 4px;
    height: 2%;
    color: #fff;
    padding: 2% 5%;
    font-size: 12px;
    margin: 0;
    position: relative;
}

.card-section1 {
    padding: 2px 5%;
    margin: 0;
    height: 10vh;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.main-card-container p {
    margin: 0;
}

.card-section2 {
    padding: 5%;
}

.card-section2 .slick-dots li button:before {
    color: #0D4C7A !important;
    width: 3px !important;
    height: 3px !important;
    line-height: 13px;
    opacity: 1.25;

}

.card-section2 .slick-dots li.slick-active button:before {
    border: 1px solid #0D4C7A !important;
    color: transparent !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
}

.machine-sub-details {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.card-sub-section3 {
    font-size: 13px;
    color: #0D4C7A;
    font-weight: bold;
}

/* .date-input {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
}

.time-input {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
} */

.datetime-input {
    padding: 15px 0px;
    font-family: "Roboto Mono", monospace;
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 2px;
    /* background-color: #0D4C7A; */
    padding: 9%;
}

::-webkit-calendar-picker-indicator {
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}

.datetime-label {
    position: relative;
    top: 20px;
    color: #A5A5A5;
    font-weight: 400;
    left: 3px;
    font-size: 12px;
}

@media (max-width: 1300px) {
    .noMachinePage {
        text-align: center;
    }
}

@media (max-width: 900px) {
    .noMachinePage {
        text-align: center;
    }
    
    .dashboard-flex{
        justify-content: center !important;
   }
}

@media (max-width: 600px){
   .myMachineHeader{
        font-size: 12px;
   }

   .myMachineHeader div button{
        font-size: 13px;
        padding: 10px 18px;
   }
}

@media (max-width: 1500px) and (min-width: 980px) {
    .total-machine-container{
        display: flex;
        gap: 10px;
        justify-content: center;
    }
}