.modal-full {
    height: 100%;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 0%;
    z-index: 1
}

.modal-active {
    height: 100%;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 0%;
    z-index: 1
}

.accept-main-flex {
    display: flex;
    padding: 0 5%;
    gap: 8%;
    flex-wrap: wrap;
}

.details-side,
.book-side {
    width: 45%;
    min-width: 350px;
}

.accepted-machine-detail {
    display: flex;
    gap: 10px;
    color: #8A8894;
    font-size: 14px;
    padding: 5px;
}

.accepted-machine-detail p {
    margin: 3px;
}

.accepted-machine-detail .propName {
    width: 150px;
}

.accepted-machine-detail .propValue {
    color: #585858;
}

.book-side .date-btn {
    color: #6B779A;
    border: 1px solid #c8d0e8;
    background: none;
    padding: 5px 15px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    width: 60px;
    height: 70px;
    border-radius: 10px;
}

.date-btn.recent-date {
    background-color: #0D4C7A;
    color: #fff;
}

.book-side .date-btn-container {
    display: flex;
    gap: 3px;
    margin-top: 10px;
}

.time-box {
    border: 1px solid #EDEDED;
    padding: 5px 12px;
    text-align: center;
    border-radius: 5px;
    color: #5C5C5C;
    font-size: 13px;
    font-weight: 600;
}

.machine-Owner-charges-details .accepted-machine-detail {
    justify-content: space-between;
    width: 80%;
}

.booking-charges {
    width: 85%;
    margin: 50px 0;
    color: #747474;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #FFECE1;
    background: #EEF1FF;
    text-align: center;
}

.booking-charges .accepted-machine-detail {
    justify-content: space-between;
    padding: 5% 2%;
}

.charge {
    font-size: 20px;
}

.booking-charges .btn-fill {
    width: 70%;
    margin-top: 0px;
}

.active-booking-section-1 {
    border-radius: 4px;
    background: rgba(255, 199, 0, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.active-booking-section-2 {
    display: flex;
}

.active-booking-machine-name p {
    margin: 0;
    color: #000;
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}

.active-booking-prop-details {
    display: flex;
    margin: 10px;
    
}

.active-booking-prop,
.active-booking-value {
    display: flex;
    justify-content: flex-start;
}

.active-booking-prop p {
    margin: 0;
    color: #A5A5A5;
    font-size: 10px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
}

.active-booking-value p {
    margin: 0;
    color: #000;
    font-size: 12px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.active-booking-section-3 {
    border-radius: 4px;
    background: rgba(13, 76, 122, 0.10);
}

.contact-booking-prop-details {
    display: flex;
    gap: 50px;
    align-items: center;
}

.contact-booking-prop {
    color: #8A8894;
    font-size: 14px;
    width: 100px;
    margin: 0 10px;
}

.contact-booking-value {
    font-size: 14px;
    font-weight: 600;
}

.flex-grow {
    flex-grow: 1;
}

.vertical-line-active {
    border-left: 2px solid #D1D1D1;
    height: 75px;
    margin: 3% 0px;
    opacity: 0.6;
    flex-grow: 1;
}

.modal-active .slick-next:before,
.modal-full .slick-next:before {
    content: '>';
}

.modal-active .slick-prev:before,
.modal-full .slick-prev:before {
    content: '<';
}

.modal-active .slick-prev:before,
.modal-active .slick-next:before,
.modal-full .slick-prev:before,
.modal-full .slick-next:before {
    font-family: 'slick';
    font-size: 18px;
    line-height: 1;
    opacity: 1;
    color: #0D4C7A;
    background-color: #fff;
    border: 1px solid #0D4C7A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 3px 5px;
}

@media (max-width: 1300px) {
    .tab-container{
        width: 100% !important;
    }

    .accepted-machine-detail{
        bottom: unset !important;
        right: unset !important;
        min-width: 350px;
    }
    
    .buyer-card-detail-container{
        padding: 10px 10px 10px 0 !important;
    }
}