.detailsComponent h3 {
    color: #0D4C7A;
}

.detail-row {
    flex-wrap: wrap;
}

.detailsComponent {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 0 0;
    padding: 20px;
}

.personal-info,
.company-info,
.company-address {
    margin-bottom: 30px;
}

.detailsComponent .label {
    margin-bottom: 5px;
}

.detailsComponent .input-field {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.divider {
    opacity: 0.8;
}

.detail-row {
    width: 100%;
    justify-content: start !important;
    gap: 8%;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.input-container:not(:last-child) {
    margin-right: 15px;
}

.detail-row .select {
    width: 110%
}

.select-react {
    width: 220px;
    margin-right: 0px !important;
}

.selectReact {
    margin-bottom: 15px;
}

.profile-picture {
    margin-top: 30px;
}

.profile-picture-container {
    width: 215px;
    border: 2px dashed #cce;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-picture-input {
    display: none;
}

.profile-picture-label {
    cursor: pointer;
    text-align: center;
}

.profile-picture-preview {
    width: 200px;
    height: 75px;
    object-fit: cover;
}

.profile-picture-label span {
    color: #888;
    font-size: 13px;
    gap: 10px;
}

.document-upload-container {
    width: 220px;
    height: 200px;
    border: 2px dashed #cce;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.document-upload-input {
    display: none;
}

.document-upload-container span .choose {
    background: #0D4C7A;
    border-Radius: 4px;
    color: #fff;
    padding: 4% 4%;
    border: #0D4C7A;
    gap: 0px;
}

.document-selected {
    color: #0F0BAB;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

}

.document-selected button {
    border: none;
    color: #4B3A5A;
    background: transparent;
    padding: 0;
    margin: 0;
}

.double-size {
    width: 60%;
}

.double-size .input-field {
    width: 100%;
}

@media (max-width: 768px) {
    .detail-row {
        flex-direction: column;
        align-items: center !important;
    }

    .input-container:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .certificate {
        margin-top: 15px;
    }

    .double-size  {
        width: 200px;
    }

    .detailsComponent {
        width: 80%;
    }

}