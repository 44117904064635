.searchSupplier {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto;
}

.searchSupplier h3 {
    margin: 0;
}

.searchSupplier h5 {
    color: #212121;
    margin-top: 0;
}

.searchSupplier .main-btn, .searchSupplier .main-btn:active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    background: #0D4C7A;
    border-radius: 8px;
    font-weight: 700;
    line-height: 120%;
    color: #FFFFFF;
    border: none;
    margin-top: 10px;
}

.formatted-date {
    font-weight: bold;
  }
  
.custom-datepicker{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.date-btn-container{
    display: flex;
    gap: 1px;
    margin-top: 10px;
}

.date-btn {
    color: #6B779A;
    border: 1px solid #c8d0e8;
    background: none;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    width: 70px;
    height: 60px;
    border-radius: 10px;
  }
  
  .date-btn:hover, .date-btn:active, .date-btn:focus {
    background-color: #6B779A;
    color: #FFF;
  }

@media (max-width:600px) {
    .grid-section {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}


.buyer-dashboard .searchSupplier {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px auto;
}

.buyer-dashboard .searchSupplier h3 {
    margin: 0;
    font-size: 24px;
}

.buyer-dashboard .searchSupplier h5 {
    color: #212121;
    opacity: 0.8;
    font-size: 16px;
}

.buyer-dashboard .main-btn {
    align-items: center;
    padding: 12px 32px;
    background: #0D4C7A;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    margin-top: 10px;
}

.react-datepicker__input-container input {
    border: none;
    cursor: pointer;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 !important;
}

.req-machine-btn:hover, .req-machine-btn:active, .req-machine-btn:focus, .req-active{
    border-bottom: 5px solid #0D4C7A;
}

.req-machine-btn:hover img, .req-machine-btn:active img, .req-machine-btn:focus img, .req-active img{
    border: 3px solid #0D4C7A;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 0px #0D4C7A;
}