.supplier-main-flex {
    display: flex;
    justify-content: space-between;
}

.main-suplier-booking-card-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.supplier-booking-card {
    width: 250px;
    height: 410px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
}

.supplier-booking-section-1 {
    width: 230px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #E7EDF2;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.supplier-booking-machine-name p {
    margin: 0;
    color: #000;
    font-family: Nunito Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}

.supplier-booking-prop-details {
    display: flex;
    margin: 10px;
}

.supplier-booking-prop,
.supplier-booking-value {
    display: flex;
    justify-content: flex-start;
}

.supplier-booking-prop p {
    margin: 0;
    color: #A5A5A5;
    font-size: 10px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
}

.supplier-booking-value p {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.supplier-booking-section-3 {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.booking-btn {
    border-radius: 4px;
    border: 0.5px solid #A5A5A5;
    background: #FFF;
    padding: 8px 12px;
    gap: 8px;
    color: #585858;
    text-align: center;
    font-size: 13px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
}

.booking-btn:hover {
    border: 0.5px solid #A5A5A5;
    background: #E7EDF2;
}

.custom-select {
    position: relative;
    display: inline-block;
    border: none;
}

.custom-select select {
    background-Color: transparent;
    border: none;
    font-Size: 15px;
    color: #333;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 40px;
}

.custom-select select option {
    padding: 40px;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    pointer-events: none;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: #0D4C7A;
}


@media (max-width:600px){
    .supplier-main-flex{
        gap: 0px;
        margin-bottom: 25px;
    }
}