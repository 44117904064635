.login-page {
    margin: 150px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 70vh;
}

.login-form,
.reset-form {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-left: -20px;
    gap: 20px;
    width: 15%;
}

.reset-form-btn {
    padding: 12px 12px;
    color: #0D4C7A;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #D4D2D5;
}

.reset-form-btn:hover {
    background: #0D4C7A;
    color: #fff;
}

.password-toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: -35px;
    transform: translateY(-250%)translateX(60%);
}

@media (max-width:1100px) {
    .login-form, .reset-form {
        width: 25%;
    }
    
    .reset-form-p{
        width: 35% !important;
    }
}

@media (max-width: 600px) {
    .login-form, .reset-form {
        width: 45%;
    }
    
    .reset-form-p{
        width: 80% !important;
    }
}