.register-page {
    margin: 150px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 70vh;
}

.heading {
    font-size: 25px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    color: #030229;
}

.btn-grp {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3%;
    margin: 40px 0px;
}

.btn-social {
    border: none;
    display: flex;
    margin: 0;
    padding: 2% 5%;
    align-items: center;
    gap: 10px;
    background: #F7F7F8;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    color: #030229;
}

.container {
    display: flex;
    align-items: center;
    width: 20%;
}

.line {
    height: 1px;
    flex-grow: 1;
    background-color: black;
    opacity: 0.1;
}

.text {
    padding: 0 10px;
    font-size: 13px;
    font-weight: 600;
}


.register-form {
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 20px;
    width: 50%;
}

.column-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}

.label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #030229;
}

.input-field {
    padding: 10px 16px;
    gap: 4px;
    /* background: #F8F8F8; */
    border-radius: 2px;
    border: 1px solid #E5E0EB;
    width: 105%;
    margin-bottom: 15px;
}

.icon, .password-toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: -35px;
    transform: translateY(-250%)translateX(60%);

}


.password-toggle-btn svg {
    fill: #aaa;
}

.password-toggle-btn:hover svg {
    fill: #333;
}

.input-container {
    position: relative;
}


.agreement-checkbox {
    font-size: 13px;
    width: 20%;
    text-align: center;
    position: relative;
    bottom: 3px;
}

a{
    text-decoration: none;
    color: #0D4C7A;
}

.submit-btn {
    padding: 12px 32px;
    align-self: center;
    color: #fff;
    background: #0D4C7A;
    border-radius: 8px;
    border: none;
    font-weight: 600;
    line-height: 120%;
    margin: 10px 0;
}

.modal{
    height: 100vh;
    background: rgba(45, 45, 45, 0.6);
    width: 100vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0%;
    z-index: 1;
}

.modal-content{
    background: #fff;
    width: 25%;
    height: 40%;
    padding: 2%;
}

@media (max-width:1020px){
    .column-container{
        justify-content: center !important;
    }

    .modal-content{
        width: 100%;
    }

    .register-page{
        width: 80%;
    }

}