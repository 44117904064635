.filter-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.textOnInput {
    position: relative;
}

.filter-label-text {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 2px;
    background-color: #fff;
    font-size: 12px;
    z-index: 1;
    color: #787885;
}

.filter-label-text::after {
    content: "";
    background-color: #fff;
    width: 100%;
    height: 13px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    color: #787885;
}

.main-buyer-card-container{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

.buyer-machine-card-container {
    display: flex;
    gap: 20px;
}

.buyer-machine-card {
    display: flex;
    gap: 15px;
    border-radius: 5px;
    background: var(--c-white, #FFF);
    box-shadow: 0px 0px 7px 0px #E8E6F8;
    width: 550px;
}

.buyer-machine-card .slick-slider {
    width: 170px;
    height: 180px;
}

.buyer-machine-card .slick-dots li{
    margin: 0;
}

.buyer-machine-card  .slick-dots li button:before {
    color: #b1b4b7 !important;
    width: 3px !important;
    height: 3px !important;
    line-height: 13px;
    font-size: 12px !important;
    opacity: 1.25;
}

.buyer-machine-card  .slick-dots li.slick-active button:before {
    color: #0D4C7A !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
}

.buyer-card-machine-img {
    width: 150px;
    height: 190px;
    padding: 15px;
}

.buyer-card-detail-container {
    padding: 10px 10px 10px 0;
}

.detail-section-1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
}

.detail-section-1 h4,
.detail-section-1 h5,
.detail-section-1 p,
.prop-details p {
    margin: 0;
}

.location-distance {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8A8894;
}

.detail-section-2 {
    margin-top: 5px;
}

.prop-details {
    display: flex;
    gap: 10px;
    color: #8A8894;
    font-size: 14px;
    padding: 5px;
}

.propName {
    width: 100px;
}

.propValue {
    color: #000;
}

.book-machine-btn {
    position: relative;
    bottom: 30px;
    float: right;
    right: 80px
}


@media (max-width: 1050px) {
    .flex-wrap {
        flex-wrap: wrap;
        justify-content: center !important;
        gap: 30px !important;
    }
    .buyer-machine-card {
        width: 500px;
    }
}


@media (max-width:600px) {
    .buyer-machine-card {
        width: 300px;
    }

    .flex-wrap {
        justify-content: center !important;
    }
}