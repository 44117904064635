  .navbar {
    position: fixed;
    width: 92%;
    z-index: 300;
    top: 0px;
  }

  .menu-icon {
    display: none;
  }

  .nav-elements div a:not(:last-child) {
    margin-right: 60px;
  }

  .nav-elements div a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
  }

  .nav-elements div a.active {
    font-weight: 500;
    position: relative;
  }

  .nav-elements div a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #FFF;
  }

  .mobile-view {
    display: none;
  }

  .active-navlink:hover {
    text-decoration: none;
    border-bottom: 2px solid white;
  }

  @media (max-width: 1300px) {
    .navbar {
      width: 100%;
      padding: 10px !important;
    }

    .nav-elements div a {
      font-size: 12px;
      margin: 5px !important;
      padding: 5px !important;
    }

    .sidebar-profile-btn {
      padding: 0 30px;
    }

  }

  @media (max-width: 900px) {
    .navbar {
      padding: 10px !important;
    }

    .nav-elements div a {
      font-size: 12px;
      margin: 5px !important;
      padding: 5px !important;
    }
  }

  @media (max-width: 600px) {
    .navbar {
      padding: 10px !important;
    }

    .nav-logged {
      left: 51px;
      width: 100%;
    }

    .menu-icon {
      display: block;
      cursor: pointer;
    }

    .nav-elements {
      position: absolute;
      right: 0;
      top: 60px;
      background-color: #0D4C7A;
      width: 0px;
      height: calc(100vh - 60px);
      transition: all 0.3s ease-in;
      display: flex;
      justify-content: center;
      overflow: hidden;
      z-index: 1;
    }

    .nav-elements.active {
      width: 270px;
    }

    .nav-elements div {
      display: flex;
      flex-direction: column;
    }

    .nav-elements div a {
      margin-right: unset !important;
      margin-top: 22px;
    }

    .sidebar-profile-btn {
      padding: 15px 80px 15px 0px !important;
    }

    .mobile-view {
      display: block;
      position: fixed;
      top: -90px;
      z-index: 400;
    }

    .desktop-view {
      display: none;
    }

    .desktop-view-name {
      display: none;
    }

    .logo {
      width: 115px;
    }
  }