.edit-btn{
    font-size: 16px;
    line-height: 148%;
    color: #0D4C7A;
    float: right;
    border: none;
    background: transparent;
    padding: 2% 1%;
}

.personal-info {
    margin-top: 5%;
}

.personal-info h3, .company-info h3,.company-address  h3{
    color: #0D4C7A;
}

.fieldName {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #ABA7AF;
}

@media (max-width: 1300px){
    .wrap{
        flex-wrap: wrap !important;
    }
}

@media (max-width: 900px){
    .wrap{
        justify-content: start !important;
    }
}