.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-fill {
    padding: 12px 42px;
    align-self: center;
    margin: 10px;
    color: #fff;
    background: #0D4C7A;
    border-radius: 4px;
    border: none;
    font-weight: 600;
    line-height: 120%;
}

.btn-outline {
    padding: 12px 42px;
    align-self: center;
    color: #0D4C7A;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #D4D2D5;
    border-radius: 4px;
    margin-top: 30px;
}

.btn-outline:hover {
    color: #fff;
    background: #0D4C7A;
}

.btn-danger-outline {
    padding: 12px 42px;
    align-self: center;
    color: #ff0000;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #ff0000;
    border-radius: 4px;
    margin-top: 30px;
}

.btn-danger-outline:hover {
    color: #fff;
    background: #ff0000;
}

.btn {
    border: none;
    background: transparent;
}

.right-container {
    top: 100px !important;
}

.back-to-top {
    position: fixed;
    cursor: pointer;
    z-index: 99;
    display: block;
    background: #e7e7e7;
    outline: none;
    border: none;
    font-size: 25px;
    right: 25px;
    bottom: 50px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

button.back-to-top:hover,
button.back-to-top:active,
button.back-to-top:focus {
    outline: 0 !important;
}

button.back-to-top svg::before,
button.back-to-top svg::after {
    content: "";
    display: block;
    line-height: 0;
    height: 0;
    margin: 18px auto 0;
    width: 0;
    outline: none;
    border-radius: 20px;
}


button.back-to-top.show:active {
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
}

.notification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.notification-container .notifications {
    background: rgba(13, 76, 122, 0.05);
    color: #0D4C7A;
    padding: 15px 20px;
    width: 100%;
    cursor:context-menu;
}

.unread {
    font-weight: bold;
}

.notifications p {
    margin: 0;
}

.notification-date {
    text-align: center;
    width: 100px;
    padding: 4px;
    background: aliceblue;
    font-size: 12px;
    color: 'gray'
}

.payment-done {
    flex-direction: column;
    width: 100vw;
    position: fixed;
    background: #fff;
    top: 11vh;
    z-index: 500;
}

@media (max-width: 1350px) {
    .right-container {
        width: 65% !important;
        left: 30% !important;
    }

}

@media (max-width: 975px) {
    .right-container {
        width: 45% !important;
        left: 40% !important;
    }
}

@media (max-width: 600px) {
    .right-container {
        width: 75% !important;
        left: 15% !important;
    }
}