.bookMyMachine-home-subHeader {
    margin-left: 5px;
}

.bookMyMachine-home-subHeader h2 {
    color: #000;
    font-family: Nunito Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal
}

.contactUs-form {
    width: 50%;
    margin-left: 10px;
}

.contactUs-flex {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.contactUs-flex .input-field {
    width: 40%;
    min-width: 200px;
    max-width: 400px;
}

.input-message {
    padding: 10px 16px;
    border-radius: 2px;
    border: 1px solid #E5E0EB;
    margin-bottom: 15px;
    width: 100%;
    height: 100px;
}

.Main-contact-page {
    margin-left: 15vw;
    position: relative;
    top: 90px;
}


.service-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.service-section-1,
.service-section-2 {
    width: 50%;
    min-width: 280px;
}

.thick-center-line {
    width: 50%;
    height: 10px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);
    margin: 50px auto;
}


@media (max-width: 1200px) {
    .contactUs-flex {
        flex-wrap: wrap;
    }

    .input-message {
        min-width: 200px;
        max-width: 400px;
    }

    .service-img{
        width: 400px !important;
    }
}

@media (max-width: 900px) {
    .our-locations {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    
    .service-img{
        width: 300px !important;
    }
}

@media (max-width: 600px) {
    .service-container {
        justify-content: center;
        gap: 20px;
    }

    .service-section-1 p, .service-section-2 p {
        text-align: justify;
        min-width: 280px;
    }

    .service-section-1 , .service-section-2 {
        align-items: flex-start !important;
    }

}