.AddDetailmodal{
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0px;
    background: rgba(45, 45, 45, 0.6);
}

.main-detail-container{
    width: 55%;
    margin: 2%;
    padding: 1% 3%;
}

.profile-page{
    margin-top: 100px;
}

@media (max-width: 768px){
    .main-detail-container{
        width: 80%;
    }

    .profile-page{
        height: 100%;
    }
    
    .profile-container{
        gap: 10px !important;
        margin: 0 !important;
    }
}