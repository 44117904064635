.expandReach {
    display: flex;
    margin: 100px 0;
    justify-content: space-around;
}

.expandReachDetails,
.expandReachImg {
    width: 425px;
}

.expandReachDetails {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.expandReachDetails h2 {
    color: #0D4C7A;
    font-family: Nunito Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.expandReachDetails button {
    display: flex;
    align-items: center;
    width: fit-content;
    border: none;
    background: transparent;
    color: #0D4C7A;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
}

.expandReachImg img {
    width: 500px;
    height: 300px;
    border-radius: 10%;
}

@media (max-width: 1100px) {
    .expandReach {
        flex-wrap: wrap;
        justify-content: center !important;
        gap: 30px !important;
        width: 75%;
    }
}


@media (max-width:600px) {
    .expandReach {
        justify-content: center !important;
        width: 100%;
    }

    .expandReachDetails,
    .expandReachImg {
        width: 325px;
    }

    .expandReachImg img {
        width: 300px;
        height: 200px;
        border-radius: 10%;
    }
}