.requestManufacturing {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.requestManufacturing h3 {
    margin: 0;
}

.requestManufacturing h5 {
    margin-top: 0;
    color: #212121;

}

.processes {
    display: flex;
    gap: 5%;
    justify-content: center;
    align-items: center;
}

.process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.process p {
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    color: #212121;
    opacity: 0.6;
}

.circular-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #eefaee;
    font-size: 16px;
    font-weight: 700;
    color: #3F3F3F;
}

.requestManufacturing button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    background: #0D4C7A;
    border-radius: 8px;
    font-weight: 700;
    line-height: 120%;
    color: #FFFFFF;
    border: none;
}

@media(max-width: 1300px) {
    .requestManufacturing {
        margin-bottom: 100px;
    }
}

@media(max-width: 600px) {
    .processes {
        flex-wrap: wrap;
    }
}