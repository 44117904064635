.modal{
    height: 100vh;
    background: rgba(45, 45, 45, 0.6);
    width: 100vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0%;
    z-index: 1;
}

.msg-modal-content{
    background: #fff;
    width: 25%;
    padding: 2%;
}