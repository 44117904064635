.buyer-booking-card-container {
    display: flex;
    gap: 20px;
}

.buyer-booking-card {
    display: flex;
    gap: 8px;
    border-radius: 5px;
    background: var(--c-white, #FFF);
    box-shadow: 0px 0px 7px 0px #d3d2dc;
    width: 550px;
    height: 250px;
}

.buyer-booking-card .slick-slider {
    width: 170px;
    height: 180px;
}

.buyer-booking-card .slick-dots li {
    margin: 0;
}

.buyer-booking-card .slick-dots li button:before {
    color: #b1b4b7 !important;
    width: 3px !important;
    height: 3px !important;
    line-height: 13px;
    font-size: 12px !important;
    opacity: 1.25;
}

.buyer-booking-card .slick-dots li.slick-active button:before {
    color: #0D4C7A !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
}

.booking-detail-section-1 {
    display: flex;
}

.vertical-line-buyer {
    border-left: 2px solid rgb(175, 172, 172);
    height: 20vh;
    margin: 8% 0px;
    opacity: 0.3;
}

.fetch {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 40px;
}


.fetch p,
.fetch b {
    margin: 0;
    color: #0D4C7A;
    width: 88%;
}

.btn-card {
    padding: 12px 12px;
    float: right;
    margin: 0px;
    color: #fff;
    background: #0D4C7A;
    border-radius: 4px;
    border: none;
    font-weight: 400;
    line-height: 85%;
}

.buyer-booking-card-container .prop-details {
    padding: 3px;
}

@media (max-width: 1100px) {
    .buyer-booking-card {
        height: auto;
        width: 500px;
    }

    .vertical-line-buyer {
        display: none;
    }

    .flex-wrap {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .fetch {
        margin-top: 0;
        width: 100% !important;
        padding: 2%;
    }

    .fetch p,
    .fetch b {
        margin: 0;
        color: #0D4C7A;
    }

    .cancel{
        position: unset !important;
        align-self: center;
        margin: 2%;
    }

    .cancel-container{
        width: 95%;
    }

}


@media (max-width:600px) {
    .buyer-booking-card {
        width: 300px;
        gap: 0px !important;
    }

    .flex-wrap {
        justify-content: center !important;
        gap: 30px !important;
    }
}