.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    position: fixed;
    width: 300px;
    min-height: 100vh;
    left: 0px;
    top: 100px;
    background: #EBECEC;
}

.profile-menu-container {
    width: 96%;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.profile-menu-container {
    background: rgba(255, 255, 255, 0.1);
}

.sidebar-profile-btn {
    padding: 0;
    background-color: transparent;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.sidebar-profile-btn h5 {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 300;
}

.switch-profile {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 500;
    color: #212121;
    width: 100%;
    gap: 20px;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,
.css-1yjjitx-MuiSwitch-track {
    background-color: #0D4C7A !important;
}

.switch-profile span {
    color: #fff !important;
}


.main-menu,
.sub-menu,
.logout {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 300;
    color: #000;
    padding: 0%;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
}

.main-menu p,
.sub-menu p,
.logout a {
    display: flex;
    align-items: center;
    gap: 10px;
}


.sidebar a {
    text-decoration: none;
    color: #000;
    font-weight: 400;
}

.sidebar a:hover,
.sidebar a:focus,
.sidebar a:active {
    background: rgba(13, 76, 122, 0.05);
    width: 88%;
    margin-left: -30%;
    padding-left: 30%;
    color: #0D4C7A;
}

.sidebar a:hover p svg,
.sidebar a:focus p svg,
.sidebar a:active p svg {
    fill: #0D4C7A !important;
}

.sidebar a:hover .disabled svg,
.sidebar a:focus .disabled svg,
.sidebar a:active .disabled svg{
    fill: #AAAAAA !important;
}

.notification{
    border-radius: 50px;
    background: #0D4C7A;
    font-size: 10px;
    color: #fff;
    padding: 2px 8px;
    margin-left: 100px;
}

@media (max-width: 1300px) {
    .sidebar {
        top: 90px
    }
}